<script setup lang="ts">
import type { Step } from 'vue3-tour'
const { tours, completeTour, showTour } = useTours()
const route = useRoute()

// Watch for the login redirect and if we need to show the tour, show it if appropriate
watch(
  () => route.path,
  async newPath => {
    if (newPath === '/' && showTour.value) {
      await nextTick()
      setTimeout(() => {
        void tours['app-tour']?.start()
      }, 500)
    }
  },
  { immediate: true }
)

useHead({
  titleTemplate: titleChunk => {
    return titleChunk ? `${titleChunk} - Retail Navigator` : 'Retail Navigator'
  }
})

const steps = ref<Step[]>([
  {
    target: '[data-app-tour-step="0"]',
    header: {
      title: 'Welcome to Retail Navigator'
    },
    content:
      "We'd love to show you around. Click 'Next' to begin your guided tour."
  },
  {
    target: '[data-app-tour-step="1"]',
    header: {
      title: 'Asset Library'
    },
    content: "Here you'll find a variety of assets for use.",
    params: {
      placement: 'right'
    }
  },
  {
    target: '[data-app-tour-step="2"]',
    header: {
      title: 'P.O.P. Store'
    },
    content: "Here you'll find a variety of items for purchase.",
    params: {
      placement: 'right'
    }
  },
  {
    target: '[data-app-tour-step="3"]',
    header: {
      title: 'Dark Mode'
    },
    content: 'Toggle between a bright or dark theme for the website.',
    params: {
      placement: 'right'
    }
  },
  {
    target: '[data-app-tour-step="4"]',
    header: {
      title: 'Search'
    },
    content:
      'Need to quickly find something? You can search the Asset Library and P.O.P. from here.'
  },
  {
    target: '[data-app-tour-step="5"]',
    header: {
      title: 'Notifications & Settings'
    },
    content:
      "By clicking on this bubble, you can view your recent notifications and quickly access your account settings. Once you've uploaded an avatar, you'll see that here."
  },
  {
    target: '[data-app-tour-step="6"]',
    header: {
      title: 'Need Support?'
    },
    content: "If you ever need help, it's always one click away."
  }
])

const onAppTourSkipped = async () => {
  await completeTour()
}

const onAppTourFinished = async () => {
  await completeTour()
}
</script>

<template>
  <div>
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <UNotifications />
    <v-tour
      name="app-tour"
      :steps="steps"
      :callbacks="{
        onSkip: onAppTourSkipped,
        onFinish: onAppTourFinished
      }"
    />
  </div>
</template>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.1s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(0.4rem);
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.3s;
}

.slide-left-enter-from {
  opacity: 0;
  transform: translate(50vw, 0);
}

.slide-left-leave-to {
  opacity: 0;
  transform: translate(-50vw, 0);
}

.slide-right-enter-from {
  opacity: 0;
  transform: translate(-50vw, 0);
}

.slide-right-leave-to {
  opacity: 0;
  transform: translate(50vw, 0);
}
</style>
