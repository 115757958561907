export default defineAppConfig({
  ui: {
    primary: 'brand',
    gray: 'neutral',
    alert: {
      rounded: 'rounded-none'
    },
    button: {
      rounded: 'rounded-none'
    },
    card: {
      // max heights are based off of the modal padding/margin
      base: 'max-h-[calc(100vh-2rem)] sm:max-h-[calc(100vh-4rem)] flex flex-col',
      rounded: 'rounded-none',
      body: {
        base: 'overflow-y-auto flex flex-col'
      }
    },
    modal: {
      padding: 'p-4 sm:p-0',
      margin: 'sm:my-8'
    },
    tabs: {
      list: {
        rounded: 'rounded-none',
        marker: {
          rounded: 'rounded-none'
        },
        tab: {
          rounded: 'rounded-none'
        }
      }
    },
    input: {
      rounded: 'rounded-none'
    },
    notification: {
      rounded: 'rounded-none'
    },
    select: {
      rounded: 'rounded-none'
    },
    textarea: {
      rounded: 'rounded-none'
    }
  }
})
