import * as Sentry from '@sentry/nuxt'
import type { RealtimeChannel } from '@supabase/realtime-js'
import type { Database, Tables } from '~~/types/supabase'

/**
 * Defines a Nuxt plugin subscribing the user to the user notifications table, allowing them to receive new
 * notifications in the client in real-time.
 */
export default defineNuxtPlugin({
  name: 'notification-subscriber',
  enforce: 'pre',
  dependsOn: ['supabase'],
  async setup() {
    let subscriptionChannel: RealtimeChannel | undefined = undefined

    // Subscribe to Supabase lifecycle events to ensure the connection is opened and closed during the app lifecycle
    const supabase = useSupabaseClient<Database>()
    const { enqueueNotification } = useNotifications()

    const onSubscribe = async (userId: string) => {
      if (subscriptionChannel !== undefined) {
        return
      }

      // Subscribe for new history records being added to this work order
      subscriptionChannel = supabase
        .channel('new_user_notifications')
        .on<Tables<'user_notifications'>>(
          'postgres_changes',
          {
            event: 'INSERT',
            schema: 'public',
            table: 'user_notifications',
            filter: `profile_id=eq.${userId}`
          },
          async payload => {
            if (payload.new.profile_id !== userId) return

            // The payload only has the user_notifications table record, hand over processing to the notifications composable to take care of the update
            try {
              await enqueueNotification(payload.new.id)
            } catch (error) {
              // Not critical functionality, so just log and move on
              Sentry.captureException(error)
              console.error('error enqueuing notification', error)
            }
          }
        )
        .subscribe()
    }

    const onUnsubscribe = async () => {
      if (subscriptionChannel === undefined) return

      await supabase.removeChannel(subscriptionChannel)

      subscriptionChannel = undefined
    }

    supabase.auth.onAuthStateChange(async (event, session) => {
      switch (event) {
        case 'INITIAL_SESSION':
        case 'SIGNED_IN':
        case 'TOKEN_REFRESHED':
        case 'USER_UPDATED':
          if (session !== null) {
            await onSubscribe(session.user.id)
          } else {
            await onUnsubscribe()
          }

          break

        case 'SIGNED_OUT':
          await onUnsubscribe()

          break
      }
    })
  }
})
