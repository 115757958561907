import type { Database, Tables, TablesInsert } from '~~/types/supabase'

type NotificationWithItem =
  | (Tables<'notifications'> & {
      type: 'Asset Update' | 'Asset Upload'
      asset_id: number
      product_id: null
      asset: Tables<'assets'>
    })
  | (Tables<'notifications'> & {
      type: 'Product Update'
      asset_id: null
      product_id: number
      product: Tables<'products'>
    })

export type UserNotification = Omit<
  Tables<'user_notifications'>,
  'profile_id'
> & {
  notification: NotificationWithItem
}

export default function useNotifications() {
  const supabase = useSupabaseClient<Database>()
  const user = useSupabaseUser()

  const currentUserNotifications = useState<UserNotification[]>(
    'current_user_notifications',
    () => []
  )

  const createNotification = async (
    notification: TablesInsert<'notifications'>
  ) => {
    const { error } = await supabase.from('notifications').insert(notification)

    if (error) throw error
  }

  /**
   * Enqueues a new notification to the current user notification state.
   *
   * This function is primarily meant to be used by the realtime subscriber in our Nuxt plugin to ensure new
   * notifications are pushed to the UI without needing a client-side reload.
   */
  const enqueueNotification = async (id: number) => {
    const { data, error } = await supabase
      .from('user_notifications')
      .select(
        `
        id,
        read_at,
        notification:notifications(
          *,
          asset:assets(*),
          product:products(*)
        )
      `
      )
      .eq('id', id)
      .returns<UserNotification[]>()
      .single()

    if (error) throw error

    currentUserNotifications.value.unshift(data)
  }

  /**
   * Retrieves the notifications for the current user, storing them to a Nuxt state variable.
   */
  const getCurrentUserNotifications = async () => {
    const { data, error } = await supabase
      .from('user_notifications')
      .select(
        `
        id,
        read_at,
        notification:notifications(
          *,
          asset:assets(*),
          product:products(*)
        )
      `
      )
      .eq('profile_id', user.value!.id)
      .order('created_at', {
        referencedTable: 'notifications',
        ascending: false
      })
      .returns<UserNotification[]>()

    if (error) throw error

    return (currentUserNotifications.value = data)
  }

  // Function to get notifications
  // - Optional parameters: userId, read (boolean)
  const getNotifications = async (
    profileId: string,
    unreadOnly: boolean = false
  ) => {
    let query = supabase
      .from('user_notifications')
      .select(
        `
        id,
        read_at,
        notification:notifications(
          *,
          asset:assets(*),
          product:products(*)
        )
      `
      )
      .eq('profile_id', profileId)

    if (unreadOnly) {
      query = query.is('read_at', null)
    }

    const { data, error } = await query.returns<UserNotification[]>()

    if (error) throw error

    return data
  }

  const unreadNotifications = computed(() =>
    currentUserNotifications.value.filter(n => n.read_at === null)
  )

  const unreadCount = computed(() => unreadNotifications.value.length)

  /**
   * Marks notifications currently visible to the authenticated user as read.
   *
   * This function will run an UPDATE statement for all notifications in the `current_user_notifications` state without
   * a `read_at` timestamp, then update the state to mark those notifications as read. This approach is used in favor of
   * updating all notifications for the user to do our best to avoid marking notifications not visible to the user
   * (such as a newly generated notification not yet visible in the state) as read.
   */
  const markCurrentUserVisibleNotificationsAsRead = async () => {
    const notificationIds = unreadNotifications.value.map(
      notification => notification.id
    )

    // Short-circuit this query if the visible notification list has no unread notifications (which also makes sure we don't accidentally mark newly added notifications not yet visible as read)
    if (notificationIds.length === 0) return

    const timestamp = new Date().toISOString()

    const { error } = await supabase
      .from('user_notifications')
      .update({ read_at: timestamp })
      .in('id', notificationIds)

    if (error) throw error

    // Update local state
    currentUserNotifications.value = currentUserNotifications.value.map(
      notification => ({
        ...notification,
        read_at: timestamp
      })
    )
  }

  return {
    unreadNotifications,
    unreadCount,
    createNotification,
    enqueueNotification,
    getCurrentUserNotifications,
    getNotifications,
    markCurrentUserVisibleNotificationsAsRead,
    currentUserNotifications
  }
}
