import Vue3Tour from 'vue3-tour'
import 'vue3-tour/dist/vue3-tour.css'

export default defineNuxtPlugin({
  name: 'tour',
  async setup(nuxtApp) {
    nuxtApp.vueApp.use(Vue3Tour)

    return {
      provide: {
        tours: nuxtApp.vueApp.config.globalProperties.$tours
      }
    }
  }
})
