import * as Sentry from '@sentry/nuxt'
import type { Session } from '@supabase/supabase-js'
import { jwtDecode } from 'jwt-decode'
import type { SupabaseJwtPayload } from '~~/types/oauth'
import type { Database, Enums } from '~~/types/supabase'

/**
 * Defines a Nuxt plugin adding extra actions to the Supabase authentication state lifecycle.
 *
 * The plugin subscribes to authentication state lifecycle events to ensure the correct user information is
 * made available throughout the application, specifically:
 *
 * - Extracting the currently authenticated user's role from their JWT and exposing it as application state
 * - Querying the currently authenticated user's permission list and exposing it as application state
 * - Storing user information to the Sentry context, which helps to better associate recorded errors with the user who was performing the action
 */
export default defineNuxtPlugin({
  name: 'rn-auth',
  enforce: 'pre',
  dependsOn: ['supabase'],
  async setup(nuxtApp) {
    const userPermissions = useState<Array<Enums<'app_permission'>>>(
      'rn_user_permissions',
      () => []
    )

    const userRole = useState<Enums<'app_role'> | null>(
      'rn_user_role',
      () => null
    )

    const supabase = useSupabaseClient<Database>()

    const handleAuthStateChange = async (
      event: string,
      session: Session | null
    ) => {
      switch (event) {
        case 'INITIAL_SESSION':
        case 'SIGNED_IN':
        case 'TOKEN_REFRESHED':
        case 'USER_UPDATED':
          if (session !== null) {
            const jwt = jwtDecode<SupabaseJwtPayload>(session.access_token)
            const role = jwt.user_role || null

            if (role === null) {
              Sentry.setUser(null)
              userRole.value = null
              userPermissions.value = []
            } else {
              Sentry.setUser({
                id: session.user.id,
                email: session.user.email,
                username: session.user.email
              })
              userRole.value = role

              // Use setTimeout to make this non-blocking
              setTimeout(async () => {
                const { data, error } = await supabase
                  .from('role_permissions')
                  .select('permission')
                  .eq('role', role)

                if (error) {
                  Sentry.captureException(error)
                  console.error(
                    'Could not query permissions on auth state change',
                    error
                  )
                  userPermissions.value = []
                } else {
                  userPermissions.value = data.map(
                    roleList => roleList.permission
                  )
                }
              }, 0)
            }
          } else {
            Sentry.setUser(null)
            userRole.value = null
            userPermissions.value = []
          }
          break

        case 'SIGNED_OUT':
          Sentry.setUser(null)
          userRole.value = null
          userPermissions.value = []
          break
      }
    }

    const {
      data: { subscription }
    } = supabase.auth.onAuthStateChange((event, session) => {
      // Make the callback non-blocking
      setTimeout(async () => {
        await handleAuthStateChange(event, session)
      }, 0)
    })

    // Cleanup function
    nuxtApp.vueApp.onUnmount(() => {
      subscription.unsubscribe()
    })
  }
})
