import type { Tour } from 'vue3-tour'
import type { Database } from '~~/types/supabase'

export const useTours = () => {
  const tours = useNuxtApp().$tours as Record<string, Tour>
  const supabase = useSupabaseClient<Database>()
  const user = useSupabaseUser()
  const showTour = useState<boolean>('showTour', () => false)

  const completeTour = async () => {
    if (!user.value?.id) return
    await supabase
      .from('profiles')
      .update({ completed_tour: true })
      .eq('user_id', user.value.id)
    showTour.value = false
  }

  const checkTourCompletion = async () => {
    if (!user.value?.id) return

    const { data: profile } = await supabase
      .from('profiles')
      .select('completed_tour')
      .eq('user_id', user.value.id)
      .single()

    // Set the tour state based on the completion status
    showTour.value = profile?.completed_tour === false
  }

  return {
    tours,
    checkTourCompletion,
    showTour,
    completeTour
  }
}
